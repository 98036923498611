<template>
    <div>
        <div :class="{'grid items-center gap-6 grid-cols-12': displayInline}">
            <div class="flex items-center" :class="classLabel">
                <div class="text-overline font-normal" :style="{'color': labelColor}" :class="labelClass">
                    {{ label || 'Téléphone portable' }}
                </div>
            </div>

            <div class="flex flex-col"
                 :class="classItem">
                <vue-tel-input
                    v-model="mobile"
                    :id="vid"
                    :class="[{error: phoneNumberInvalid && errorField, '!border-[#1F2945]': from !== 'maslow'}]"
                    style="height: 3rem"
                    default-country="FR"
                    enabledCountryCode
                    mode="international"
                    :preferred-countries="['FR', 'BE', 'DE', 'ES', 'IT']"
                    @input="updateNumber"
                    @country-changed="updateNumber"
                    @blur="() => {errorField = phoneNumberInvalid || errorMessage}"
                    :input-options="{showDialCode: true, inputMode: 'tel', type: 'tel'}"
                    inputmode="tel"
                    dynamicPlaceholder
                    required show-validation-errors
                    ref="telInput">
                </vue-tel-input>

                <div v-if="showValidationErrors && (errorField || errorMessage)"
                     class="text-overline mt-1 text-red-error form-error-display">
                    {{required && (!mobile || mobile.length <= 3) ? errorMessage ? errorMessage : "Champ requis" : `Veuillez saisir un numéro de téléphone ${fixe ? "fixe " : ""}valide` }}
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {useField} from 'vee-validate';

//passer en string dans returnValue les valeurs que l'ont veut recevoir dans le v-model, "all" renvoi tout.
// Pour récupérer les donnés en tant que propriété d'un objet, crée un objet dans le parent pour mettre en v-model et mettre un watcher dessus.

const props = defineProps({
    modelValue: {},
    returnValue: {
        type: String,
        default: 'nationalNumber'
    },
    showValidationErrors: {
        type: Boolean,
        default: false
    },
    vid: {
        type: String,
    },
    required: {
        type: Boolean,
        default: false
    },
    fixe: {
        type: Boolean,
        default: false,
    },
    indicatifPays: {
        type: String,
        default: "33"
    },
    label: {
        type: String,
        default: null
    },
    displayInline: {
        type: Boolean,
        default: false
    },
    colsLabelInline: {
        type: String,
        default: 'col-span-8'
    },
    colsItemInline: {
        type: String,
        default: 'col-span-4'
    },
    labelColor: {
        type: String,
        default: "#2C2C2C"
    },
    labelClass: {
        type: String,
        default: ""
    },
    from: {
        type: String,
        default: 'maslow'
    }
});

const emit = defineEmits(['update:modelValue', 'returnObject'])

const mobile = ref('');
const phoneNumberInvalid = ref(true);
const fullPayload = ref(null);
const showError = ref(false);
const errorField = ref(false);
const telInput = ref(null)

const vid = toRef(props, 'vid');
const rules = computed(() => {
    let isRequired = props.required ? "required" : null;
    let isFixe = props.fixe ? "telephoneFixe" : null;
    if (isRequired) {
        if (isFixe) {
            return isRequired + "|" + isFixe;
        }
        return isRequired
    } else if (isFixe) {
        return isFixe;
    }
    return null;
});
const {
    value: inputValue,
    errorMessage,
    handleBlur,
    handleChange,
    meta,
} = useField(vid, rules.value, {
    initialValue: props.modelValue,
    syncVModel: true
});

onMounted(() => {
    mobile.value = "+33";
    nextTick(() => {
        if (props.modelValue) {
            if (props.modelValue.toString().length < 10) {
                mobile.value = "+" + props.indicatifPays + props.modelValue
            } else {
                mobile.value = props.modelValue;
            }
        }
    }).then(() => {
        updateNumber();
    });
})

function updateNumber() {
    if (telInput.value !== null) {
        const payload = telInput.value.phoneObject;
        phoneNumberInvalid.value = !payload.valid;
        fullPayload.value = payload;
    }
}

watch(fullPayload, (newValue, oldValue) => {
    if (newValue.valid === true) {
        handleChange(fullPayload.value[props.returnValue])
        emit('update:modelValue', fullPayload.value[props.returnValue]);
        emit('returnObject', fullPayload.value)

    } else if (newValue.valid === false && oldValue?.valid) {
        handleChange(null)
        emit('update:modelValue', null);
        emit('returnObject', {});
    }
})
</script>

<script>
export default {
    name: "Telephone",
    computed: {
        classItem() {
            let obj = {};
            obj[this.colsItemInline] = this.displayInline;

            return obj;
        },
        classLabel() {
            let obj = {'mb-3': !this.displayInline};
            obj[this.colsLabelInline] = this.displayInline;

            return obj;
        }
    }
}
</script>

<style scoped lang="scss">
.vue-tel-input.error {
    border-color: red;
}

.vue-tel-input {
    border-color: #E9E3E2;
}

.vue-tel-input:focus-within {
    box-shadow: unset !important;
    border-color: #C2BBBA;
}
</style>
